import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeUser } from '../../../../redux-store/slices/userSlice';
import { useIntl } from 'react-intl';
const UserMenu = () => {

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state.persistedReducer.user.user);

  const logOutHandler = () => {
    setTimeout(() => {
      dispatch(removeUser());
      localStorage.removeItem('token');
      navigate('/auth/login');
    }, 1500)
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <Link to={'/profile/settings'} className='menu-link px-5'>
          {intl.formatMessage({ id: "MY_ACCOUNT" })}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <button onClick={logOutHandler} className='menu-link px-5 border-0 outline-0 bg-transparent'>
          {intl.formatMessage({ id: "SIGN_OUT" })}
        </button>
      </div>
    </div>
  )
}

export { UserMenu }

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    mode: 'light'
}


const themeMode = createSlice({
    name: 'themeMode',
    initialState,
    reducers: {
      setThemeMode: (state, actions) => {
         return {
            ...state,
            mode: actions.payload
         }
      }
    }
})

export const { setThemeMode } = themeMode.actions

export default themeMode.reducer


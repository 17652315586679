// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTIcon } from '../../../helpers'
import { Link } from 'react-router-dom';

type Props = {
  className: string
  backGroundColor: string
  title: string
  link: string
  iconName: string,
  count: number
}

const MixedWidget15: FC<Props> = ({ className, backGroundColor, title, link, iconName,count }) => {

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{ backgroundColor: backGroundColor }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column mb-7'>
          {/* begin::Title  */}
          <Link to={link} className='text-dark text-hover-primary fw-bolder fs-3'>
            {title}
          </Link>
          {/* end::Title */}
        </div>
        {/* end::Wrapper */}

        <div className='row g-0'>
          {/*begin::Col*/}
          <div className='col-12'>
            <div className='d-flex align-items-center mb-9 me-2'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-40px me-3'>
                <div className='symbol-label bg-body bg-opacity-50'>
                  <KTIcon iconName={iconName} className='fs-1 text-dark' />
                </div>
              </div>
              {/*end::Symbol*/}

              {/*begin::Title*/}
              <div>
                <div className='fs-5 text-dark fw-bolder lh-1'>{count}</div>
              </div>
              {/*end::Title*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MixedWidget15 }

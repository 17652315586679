
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import languageSlice from './languageSlice';
import themeMode from './themeModeSlice';



const rootReducer = combineReducers({
    user: userSlice,
    languages: languageSlice,
    thememode: themeMode
})

export default rootReducer

import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_turniket/layout/core'
import {
  MixedWidget15,
} from '../../../_turniket/partials/widgets';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setLanguages } from '../../../redux-store/slices/languageSlice';
import { removeUser } from '../../../redux-store/slices/userSlice';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const DashboardPage = ({ intl,dashboardStatistics }) => (
  <>
    <div className='row gy-5 g-xl-5'>

      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#CBD4F4' count={dashboardStatistics?.admins_count} title={intl.formatMessage({ id: "ADMINS" })} link="/admins" iconName='security-user' />
      </div>
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#F7D9E3' count={dashboardStatistics?.permission_count} title={intl.formatMessage({ id: "PERMISSIONS" })} link='/permissions' iconName='shield-tick' />
      </div>
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#CBF0F4' count={dashboardStatistics?.role_count} title={intl.formatMessage({ id: 'ROLES' })} link='/roles' iconName='user-tick' />
      </div>
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#FCAF17' count={dashboardStatistics?.language_count} title={intl.formatMessage({ id: 'LANGUAGES' })} link='/languages' iconName='flag' />
      </div>
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#F2F3F5' count={dashboardStatistics?.settings_count} title={intl.formatMessage({ id: 'SETTINGS' })} link='/settings' iconName='setting' />
      </div>
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-5' backGroundColor='#EED3B1' count={dashboardStatistics?.workers_count} title={intl.formatMessage({ id: 'WORKERS' })} link='/workers' iconName='user' />
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const languages = useSelector((state: any) => state.persistedReducer.languages.languages)

  const currentUser = useSelector((state: any) => state.persistedReducer.user.user);
  const API_URL = process.env.REACT_APP_BASE_URL;
  interface DashboardStatisticsProps {
    admins_count: number,
    role_count: number,
    language_count: number,
    workers_count: number,
    settings_count: number,
    permission_count: 0
  }
  const [dashboardStatistics, setDashboardStatistics] = useState<DashboardStatisticsProps>({
    admins_count: 0,
    role_count: 0,
    language_count: 0,
    workers_count: 0,
    settings_count: 0,
    permission_count: 0
  })
  const getAllLanguages = async () => {
    try {
      const result: any = await axios.get(`${API_URL}/api/languages`);

      if (result.status === 200) {
        dispatch(setLanguages(result?.data?.languages))
      } else {
        throw new Error(result?.data)
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        dispatch(removeUser());
        navigate('/auth/login');
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  }

  const getStatistics = async () => {
    try {
      const request = await axios.get(`${API_URL}/api/dashboard`);
      if (request.status === 200) {
        const dataCopy = request.data;
        delete dataCopy.status;
        setDashboardStatistics(dataCopy)
      } else {
        throw new Error(request?.data)
      }

    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        dispatch(removeUser());
        navigate('/auth/login');
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  }
  useEffect(() => {
    if (languages.length === 0) {
      getAllLanguages();
    }
  }, [languages]);

  useEffect(() => {
    getStatistics();
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>

        {intl.formatMessage({ id: "HELLO" })}{currentUser?.name}
      </PageTitle>
      <DashboardPage intl={intl} dashboardStatistics={dashboardStatistics} />
    </>
  )
}

export { DashboardWrapper }
